import en from '../strings/en.json';
import hi from '../strings/hi.json';
import gu from '../strings/gu.json';
import mr from '../strings/mr.json';
import kn from '../strings/kn.json';
import te from '../strings/te.json';

const strings = {};

strings.en = en;
strings.hi = { ...strings.en, ...hi };
strings.gu = { ...strings.en, ...gu };
strings.mr = { ...strings.en, ...mr };
strings.kn = { ...strings.en, ...kn };
strings.te = { ...strings.en, ...te };

export default strings;
