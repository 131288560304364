import React from 'react';

import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Carousel({ children, autoPlay, autoPlayInterval }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = children.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (activeStep > 0) {
        return (prevActiveStep - 1) % maxSteps;
      } else {
        return maxSteps - 1;
      }
    });
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: '100%', flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        autoplay={autoPlay}
        interval={autoPlayInterval}
      >
        {children.map((child, index) =>
          Math.abs(activeStep - index) <= 2 ? child : <div key={index} />,
        )}
      </AutoPlaySwipeableViews>
      {maxSteps > 1 && (
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext}>
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack}>
              <KeyboardArrowLeft />
            </Button>
          }
          sx={{ padding: 0 }}
        />
      )}
    </Box>
  );
}

export default Carousel;
